<template>
  <div>
		<!-- HANDELBAY INNOVATIVE -->
		<section id="handelbay-innovative">
			<div class="container">
				<div class="row">
					<div class="col-md-5 col-sm-6 col-md-offset-1 text-left">
						<div class="title" itemscope itemtype="https://handelbay.com/">
							<h2 class="title-1" itemprop="HandelBay Innvador">¿Te encanta ser innovador?</h2>
							<h3 class="title-2" itemprop="HandelBay en tu día a día">Es hora de usar
								<br> Markley HandelBay en tu día a día</h3>
							<span class="handelbay-active"></span>
						</div>
						<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
							<p itemprop="HandelBay es la solución">
								HandelBay es la solución que tanto estabas esperando para el área de compras y el área comercial.
							</p>
							<p itemprop="HandelBay te permite optimizar">
								Nosotros creamos un producto que te permite optimizar tu día a día, ejecutando las tareas operativas de manera más eficiente para que te dediques a las acciones que generan valor a tu compañía.
							</p>
						</div>
					</div>
					<div class="col-md-6 col-sm-6">
						<div class="computers">
							<img src="../assets/images/landing/handelbay/handelbay_computador.png" alt="Es hora de usar Markley HandelBay en tu día a día">
						</div>
					</div>
				</div>
				<!-- .row -->
			</div>
			<!-- .container -->
		</section>
		<!-- END HANDELBAY INNOVATIVE -->

		<!-- .handelbay-background-curva -->
		<section id="handelbay-background-curva" class="top-backogrund-curva" style="margin-top:80px !important">
		</section>
		<!-- .handelbay-background-curva -->

		<!-- HANDELBAY ITEMS -->
		<section id="items-handelbay">
			<div class="">
				<div class="container">
					<div class="row">
						<div class="col-md-8 col-sm-12 col-md-offset-2">
							<div class="text-center tabs-handelbay-planes">
								<!-- Nav tabs -->
								<ul class="nav nav-tabs" role="tablist">
									<li role="presentation" class="active">
										<a href="#Abastecimiento" aria-controls="Abastecimiento" role="tab" data-toggle="tab" class="abaste" onclick="$('.context').show();">Abastecimiento
											<span></span>
										</a>
									</li>
									<li role="presentation">
										<a href="#Gestion_comercial" aria-controls="Gestion_comercial" role="tab" data-toggle="tab" class="gestion" onclick="$('.context').hide();">Gestión comercial y ventas
											<span></span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<!-- Tab panes -->
				<div class="tab-content">
					<div role="tabpanel" class="tab-pane active" id="Abastecimiento">
						<div class="container">
							<div class="row">
								<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
									<div class="title" itemscope itemtype="https://handelbay.com/">
										<h2 class="title-2" itemprop="Abastecimiento">Abastecimiento</h2>
										<span class="handelbay-active"></span>
									</div>
									<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
										<p itemprop="plataforma">
											Con el uso de Markley HandelBay como tu plataforma de compras empresariales, no tendrás que usar tantas herramientas ni mover datos de un lado a otro y mucho menos perder información valiosa para tu empresa. Markley HandelBay es la solución para las áreas de compras
											innovadoras que siempre están un paso adelante, que optimizan sus procesos y generan valor a su cliente interno.
										</p>
									</div>
									<div class="handelbay-tradicional-title text-center">
										<h4>HandelBay |vs| Tradicional
											<span class="line-left"></span>
											<span class="line-right"></span>
										</h4>
										<h5 style="vertical-align:middle;line-height: 20px;font-family:gotham_light;"> A continuación, encontrarás una comparación en <b> tiempo</b>, entre los procesos <br>realizados en el método <span style="font-weight: bold;font-family:Gotham-Bold;"> tradicional </span> y en <span style="color:#30e7a3; font-weight: bold;font-family:Gotham-Bold;"> HandelBay </span>                                    </h5>
									</div>
								</div>
							</div>
							<!-- .row -->
						</div>
						<!-- .container -->
						<div class="handelbay-tradicional-content">
							<div class="container">
								<div class="row">
									<div class="col-md-10 col-sm-12 col-md-offset-1 background-2 background-4">
											<div class="title-last">
													<span>Búsqueda de
																	<br>proveedores</span>
											</div>
											<div class="row handelbay-progress">
													<div class="col-md-4 col-sm-4 first-text" itemscope>
															<p class="text-left-handelbay" itemprop="cliente">
																	Búsqueda de proveedores
															</p>
													</div>
													<div class="col-md-7 col-sm-7">
															<div class="text-right-handelbay">
																	<div class="barra-progress" itemscope>
																			<div>
																					<span style="width: 73px;"></span>
																			</div>
																			<span itemprop="Investigación">Directorios - internet</span>
																	</div>
															</div>
													</div>
											</div>
											<!-- .row -->

											<div class="row handelbay-progress">
													<div class="col-md-4 col-sm-4 first-text" itemscope>
															<p class="text-left-handelbay" itemprop="mercados">
																	Solicitud de información y documentación de proveedores
															</p>
													</div>
													<div class="col-md-7 col-sm-7">
															<div class="text-right-handelbay">
																	<div class="barra-progress" itemscope>
																			<div>
																					<span style="width: 84px;"></span>
																			</div>
																			<span itemprop="labor comercial">Correo electrónico</span>
																	</div>
															</div>
													</div>
											</div>

											<div class="row handelbay-progress">
													<div class="col-md-4 col-sm-4 first-text" itemscope>
															<p class="text-left-handelbay" itemprop="mercados">
																	Generar recomendaciones de proveedores
															</p>
													</div>
													<div class="col-md-7 col-sm-7">
															<div class="text-right-handelbay">
																	<div class="barra-progress" itemscope>
																			<div>
																					<span style="width: 84px;"></span>
																			</div>
																			<span itemprop="labor comercial">Correo electrónico</span>
																	</div>
															</div>
													</div>
											</div>

											<div class="row handelbay-progress">
													<div class="col-md-4 col-sm-4 first-text" itemscope>
															<p class="text-left-handelbay" itemprop="mercados">
																	Clasificar proveedores
															</p>
													</div>
													<div class="col-md-7 col-sm-7">
															<div class="text-right-handelbay">
																	<div class="barra-progress" itemscope>
																			<div>
																					<span style="width: 84px;"></span>
																			</div>
																			<span itemprop="labor comercial">Excel o similar</span>
																	</div>
															</div>
													</div>
											</div>
									</div>
								</div>
								<!-- .row -->

								<div class="row">
										<div class="col-md-10 col-sm-12 col-md-offset-1 background-1">
												<div class="title-last">
														<span>Proceso de
																		compra</span>
												</div>
												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="cliente">
																		Comunicación interna con el área de compras
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 100px;"></span>
																				</div>
																				<span itemprop="Investigación">ERP / correo electrónico</span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="mercados">
																		Requisiciones a compras
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 84px"></span>
																				</div>
																				<span itemprop="labor comercial">ERP / correo electrónico</span>
																		</div>
																</div>
														</div>
												</div>
												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="cliente">
																		Solicitud de cotizaciones
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 84px;"></span>
																				</div>
																				<span itemprop="Investigación">Correo electrónico / portal de cada empresa</span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="procesos de compra">
																		Publicación de licitaciones públicas
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 84px;"></span>
																				</div>
																				<span itemprop="portales">Correo electrónico / portal de cada empresa</span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																		Envío y recepción de archivos multimedia
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>
																				<span itemprop="visitas">Correo electrónico / portal de cada empresa</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																		Recepción de cotizaciones
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>
																				<span itemprop="visitas">Correo electrónico / portal de cada empresa</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																		Análisis de proveedores (reputación /recomendaciones)
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>
																				<span itemprop="visitas">Correo electrónico / teléfono / referidos / terceros</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																		Comparación de cotizaciones
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>
																				<span itemprop="visitas">Excel o similar</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																		Solicitud de aprobaciones para comprar
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>
																				<span itemprop="visitas">Correo electrónico</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																		Generación de órdenes de compra
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>
																				<span itemprop="visitas">ERP / editor de texto / correo electrónico</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																		Generación de órdenes de compra
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>
																				<span itemprop="visitas">ERP / editor de texto / correo electrónico</span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->
										</div>
								</div>
								<!-- .row -->

								<div class="row">
										<div class="col-md-10 col-sm-12 col-md-offset-1 background-1">
												<div class="title-last">
														<span>Proceso <br>poscompra</span>
												</div>
												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="cliente">
																		Seguimiento post compra
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 78px;"></span>
																				</div>

																				<span itemprop="Investigación">Correo electrónico / teléfono</span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="mercados">
																		Comunicación con proveedores por proceso (chat)
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 84px;"></span>
																				</div>

																				<span itemprop="labor comercial">NA</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="procesos de compra">
																		Trazabilidad de la información
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 100px;"></span>
																				</div>

																				<span itemprop="portales">Entre ERP y correo electrónico / múltiples fuentes</span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																		Clasificación y evaluación de proveedores
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>

																				<span itemprop="visitas">ERP / formatos de cada empresa</span>
																		</div>
																</div>
														</div>
												</div>
										</div>
								</div>

								<div class="row">
										<div class="col-md-10 col-sm-12 col-md-offset-1 background-1">
												<div class="title-last">
														<span>Optimización de<br>procesos</span>
												</div>
												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="cliente">
																		Históricos de precios de compra
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 78px;"></span>
																				</div>

																				<span itemprop="Investigación">ERP / correo electrónico</span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="mercados">
																		Generación de reportes
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 84px;"></span>
																				</div>

																				<span itemprop="labor comercial">Información de ERP y proceso manual / no se realiza</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="procesos de compra">
																		Replicar proceso de compra
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 100px;"></span>
																				</div>

																				<span itemprop="portales">- - </span>
																		</div>
																</div>
														</div>
												</div>
												<!-- .row -->

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																		Programar un proceso de compra recurrente
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>

																				<span itemprop="visitas">--</span>
																		</div>
																</div>
														</div>
												</div>

												<div class="row handelbay-progress">
														<div class="col-md-4 col-sm-4 first-text" itemscope>
																<p class="text-left-handelbay" itemprop="portafolio">
																		Transferencia de procesos de compra
																</p>
														</div>
														<div class="col-md-7 col-sm-7">
																<div class="text-right-handelbay">
																		<div class="barra-progress" itemscope>
																				<div>
																						<span style="width: 73px;"></span>
																				</div>

																				<span itemprop="visitas">Correo electrónico / verbal</span>
																		</div>
																</div>
														</div>
												</div>
										</div>
								</div>
								<!-- .row -->
							</div>
						</div>
					</div>
					<div role="tabpanel" class="tab-pane" id="Gestion_comercial">
						<div class="container">
								<div class="row">
										<div class="col-md-10 col-sm-12 col-md-offset-1 text-left">
												<div class="title" itemscope itemtype="https://handelbay.com/">
														<h1 class="title-2" itemprop="Gestion_comercial">Gestión comercial</h1>
														<span class="handelbay-active"></span>
												</div>
												<div class="handelbay-text" itemscope itemtype="https://handelbay.com/">
														<p itemprop="plataforma">
																¿Te imaginás un CRM que se diligencia automaticamente? ¿Qué tal que nuevos clientes te busquen para comprar? ¿Cómo le contás al mundo tu buena reputación y excelente calificaciones como proveedor? En Markley HandelBay podés hacer esto, además tendrás tu portafolio en línea 24/7. Queremos ayudarte a ser el mejor proveedor.
														</p>
												</div>
												<div class="handelbay-tradicional-title text-center">
														<h4>HandelBay |vs| Tradicional
																<span class="line-left"></span>
																<span class="line-right"></span>
														</h4>
												</div>
										</div>
								</div>
								<!-- .row -->
							</div>
							<!-- .container -->
							<div class="handelbay-tradicional-content">
									<div class="container">
											<div class="row">
													<div class="col-md-10 col-sm-12 col-md-offset-1 background-1">
															<div class="title-last">
																	<span>Gestión
																					<br>comercial</span>
															</div>
															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="cliente">
																					Buscar e identificar un nuevo cliente
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 100px;"></span>
																							</div>
																							<span itemprop="Investigación">Investigación de mercados / internet</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="mercados">
																					Llegar a nuevos mercados y dar a conocer la empresa
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px"></span>
																							</div>
																							<span itemprop="labor comercial">Labor comercial</span>
																					</div>
																			</div>
																	</div>
															</div>

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="procesos de compra">
																					Buscar nuevos procesos de compra (oportunidades)
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 78px;"></span>
																							</div>
																							<span itemprop="portales">Múltiples portales de empresas y agremiaciones</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="portafolio">
																					Dar a conocer el portafolio completo
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 89px;"></span>
																							</div>
																							<span itemprop="visitas">Página web / visitas comerciales</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="reputación">
																					Dar a conocer la reputación de la empresa y nombre
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 100px;"></span>
																							</div>
																							<span itemprop="documentación">Envío de documentación / presentaciones comerciales a clientes</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="Gestión">
																					Gestión de clientes
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 94px;"></span>
																							</div>
																							<span itemprop="CRM">CRM / excel o similar</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->
													</div>
											</div>
											<!-- .row -->

											<div class="row">
													<div class="col-md-10 col-sm-12 col-md-offset-1 background-2">
															<div class="title-last">
																	<span>Proceso
																					<br>De venta</span>
															</div>
															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="cliente">
																					Recepción de invitaciones a participar en procesos
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 73px;"></span>
																							</div>
																							<span itemprop="Investigación">Múltiples portales de empresa y agremiaciones</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="mercados">
																					Invitaciones acertadas según el portafolio de la empresa
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>
																							<span itemprop="labor comercial">NA</span>
																					</div>
																			</div>
																	</div>
															</div>

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="procesos de compra">
																					Solicitud de Referencias comerciales
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 94px;"></span>
																							</div>
																							<span itemprop="portales">Correo electrónico</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="portafolio">
																					Participación en licitaciones y o procesos de mayor cuantía
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>
																							<span itemprop="visitas">Múltiples portales de empresas y agremiaciones</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="reputación">
																					Recordatorios de cotización pendientes
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 94px;"></span>
																							</div>
																							<span itemprop="documentación">NA</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="Gestión">
																					Cotizar
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 78px;"></span>
																							</div>
																							<span itemprop="CRM">Correo electrónico</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="Gestión">
																					Envío de información comercial y documentación de la empresa
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>
																							<span itemprop="CRM">Correo electrónico</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->
													</div>
											</div>
											<!-- .row -->

											<div class="row">
													<div class="col-md-10 col-sm-12 col-md-offset-1 background-1 background-3">
															<div class="title-last">
																	<span>Proceso
																					<br>posventa</span>
															</div>
															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="cliente">
																					Comunicación individual con clientes por proceso (chat)
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>
																							<span itemprop="Investigación">NA</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="procesos de compra">
																					Seguimiento postventa
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>
																							<span itemprop="portales">Correo electrónico</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="portafolio">
																					Recepción de calificaciones
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 73px;"></span>
																							</div>
																							<span itemprop="visitas">NA / Encuesta</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->
													</div>
											</div>
											<!-- .row -->

											<div class="row">
													<div class="col-md-10 col-sm-12 col-md-offset-1 background-2 background-4">
															<div class="title-last">
																	<span>Optimización
																					<br>del proceso
																					<br>de venta</span>
															</div>
															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="cliente">
																					Gestión de ventas comerciales
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 78px;"></span>
																							</div>

																							<span itemprop="Investigación">CRM / excel o similar</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="mercados">
																					Generación de reportes de procesos de comerciales de ventas
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 84px;"></span>
																							</div>

																							<span itemprop="labor comercial">ERP</span>
																					</div>
																			</div>
																	</div>
															</div>

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="procesos de compra">
																					Modelo de asignación de cuentas (KAMs)
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 100px;"></span>
																							</div>

																							<span itemprop="portales">CRM /excel o similar</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="portafolio">
																					Transferencia de responsabilidad de procesos de venta
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 73px;"></span>
																							</div>

																							<span itemprop="visitas">Correo electrónico</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->

															<div class="row handelbay-progress">
																	<div class="col-md-4 col-sm-4 first-text" itemscope>
																			<p class="text-left-handelbay" itemprop="reputación">
																					Trazabilidad de procesos de venta
																			</p>
																	</div>
																	<div class="col-md-7 col-sm-7">
																			<div class="text-right-handelbay">
																					<div class="barra-progress" itemscope>
																							<div>
																									<span style="width: 78px;"></span>
																							</div>

																							<span itemprop="documentación">CRM / excel o similar</span>
																					</div>
																			</div>
																	</div>
															</div>
															<!-- .row -->
													</div>
											</div>
											<!-- .row -->
									</div>
							</div>
					</div>
				</div>

				<div class="container">
					<div class="row">
						<div class="col-md-8 col-sm-12 col-md-offset-2">
							<div class="text-center tabs-handelbay-planes">
								<!-- Nav tabs -->
								<ul class="nav nav-tabs" role="tablist">
									<li role="presentation" class="active">
										<a href="#Abastecimiento" aria-controls="Abastecimiento" role="tab" data-toggle="tab" class="abaste" onclick="$('.context').show();">Abastecimiento
											<span></span>
										</a>
									</li>
									<li role="presentation">
										<a href="#Gestion_comercial" aria-controls="Gestion_comercial" role="tab" data-toggle="tab" class="gestion" onclick="$('.context').hide();">Gestión comercial y ventas
											<span></span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- END HANDELBAY ITEMS -->

		<!-- .handelbay-background-curva -->
		<section id="handelbay-background-curva-bottom">
		</section>
		<!-- .handelbay-background-curva -->

		<!-- TRADITIONAL HANDELBAY -->
		<section id="traditional-handelbay">
			<div class="container">
				<div class="row">
					<div class="col-md-10 col-sm-12 col-md-offset-1">
						<div class="handelbay-text text-center context" itemscope itemtype="https://handelbay.com/">
							<p itemprop="plataforma">
								En Markley HandelBay es posible realizar la trazabilidad y seguimiento a todo lo asociado al proceso
								<br>de abastecimiento, generando valor al área de compras a la hora de tomar decisiones y realizar el trabajo operativo, incluso disminuyendo un 70% el tiempo invertido en realizar un proceso de compra.
							</p>
						</div>
						<div class="handelbay-tradicional-title text-center context">
							<h4>Markley HandelBay | Tradicional
								<span class="line-left"></span>
								<span class="line-right"></span>
							</h4>
						</div>
						<img src="../assets/images/landing/handelbay/handelbay_tradicional_full.png" class="handelbay_img_tradicional context" alt="">
						<div class="title-footer text-center">
							<h2 class="title">¿Querés comprar o vender en Markley HandelBay?</h2>
							<a :href="global.whatsapp" class="btn btn-handelbay-success" target="_blanck" data-target="#modal-demo">Agendá tu demo</a>
							<a :href="global.sign_up" class="btn btn-handelbay-success btn-handelbay-black">
								REGISTRATE GRATIS
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- END TRADITIONAL HANDELBAY -->
		<PorqueHandelbay />
		<ModalTuDemo />

  </div>
</template>
<script>
import global from "@/components/Global.vue"
import PorqueHandelbay from "@/components/PorqueHandelbay.vue"
import ModalTuDemo from "@/components/ModalTuDemo.vue"
export default {
	components:{
		PorqueHandelbay,
		ModalTuDemo
	},
	data(){
		return{
			global: global
		}
	}
}
</script>